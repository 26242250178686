import React from 'react';
import UserHeaderControl from 'app/components/headerBar/userHeaderControl.jsx';
import { browserHistory, Link } from 'react-router';
import LoginFacebookButton from 'app/components/login/loginFacebookButton.jsx';
import styles from './styles/headerBar_style.css';
import LangBar from 'app/containers/langBar/langBar_container.jsx';

export default class HeaderBar extends React.Component {
    _renderUserHeaderControl() {
        if (this.props.user.isLogin) {
            return (<UserHeaderControl
                default={this.props.default}
                data={this.props.user.userData}
                userActions={this.props.userActions} />);
        } else {
            var isLoading = this.props.user.isLoading;
            return (
                <LoginFacebookButton isLoading={isLoading} />
            );
        }
    }
    _onClickSwitchControlPanel() {
        this.props.controlPanelActions.switchDisplayControlPanel();
    }
    render() {
        var style = styles.gray;
        var logoSrc = "/images/logo/pickboz-logo.png";
        switch (this.props.default) {
            case 'gray':
                style = styles.gray;
                logoSrc = "/images/logo/pickboz-logo.png";
                break;
            case 'white':
                style = styles.white;
                logoSrc = "/images/logo/pickboz-logo-white.png";
                break;
            default:
        }
        return (
            <div className={styles.header_board + ' ' + style}>
                <div className={styles.show_menu_button} onClick={this._onClickSwitchControlPanel.bind(this)}>
                    <i className="fa fa-bars" aria-hidden="true"></i>
                </div>
                <a href="/"><img className={styles.main_logo} src={logoSrc} width={165}/></a>
                <div style={{ flex: 1 }}></div>
                <LangBar />
                {this._renderUserHeaderControl()}
            </div>
        )
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3EhixmXYeKPQkdSVL8mTRi{\n    background-color: #02499d;\n    display: flex;\n    flex-direction: row;\n    text-decoration: none;\n    color: #fff;\n    padding: 5px 10px;\n    align-items: center;\n    font-weight: bold;\n    border-radius: 3px;\n    width: 198px;\n    justify-content: center;\n}\n\n@media only screen and (max-width: 500px) {\n    ._3EhixmXYeKPQkdSVL8mTRi {\n        font-size: 12px;\n    }\n}\n\n._3EhixmXYeKPQkdSVL8mTRi:hover{\n    color: #fff;\n    opacity: 0.8;\n    text-decoration: none;\n}\n", ""]);

// exports
exports.locals = {
	"facebook_button": "_3EhixmXYeKPQkdSVL8mTRi"
};
import React from 'react';
import {Link,browserHistory} from 'react-router';
import {GFormInput,GButton,GTimePicker,Dropdown,Panel,TabMenu,DialogModal,AlertBox,GModal} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import PickVotePanel from 'app/containers/vote/pickVotePanel_container.jsx';
import EntryDatePicker from 'app/containers/entry/entryDatePicker_container.jsx';

import VoteTimePanel from 'app/containers/entry/voteTimePanel_container.jsx';
import EntryGuide from 'app/components/guide/entryGuide.jsx';

import DeadLineTimer from 'app/components/entry/deadLineTimer.jsx';
import LoginFacebookButton from 'app/components/login/loginFacebookButton.jsx';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/entryMain_style.css';

import _ from 'lodash';

//set moment lib qua parse tieng viet
import moment from 'moment';
import 'moment/locale/vi';
import 'moment/locale/ja';
import 'moment/locale/en-ie';

moment.locale('vi');
moment.updateLocale('vi', {months : 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });
function checkIsVoted(votes){
    if(votes.length<=1) return true;
    for(var i=0;i<votes.length;i++){
        if(votes[i].picked) return true;
    }
    return false;
}

function checkIsSelectedDate(selectedDays){
    for(var i=0;i<selectedDays.length;i++){
        if(selectedDays[i].picked) return true;
    }
    return false;
}
export default class EntryPickPage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            description:'',
            image:null,
            visibleMessageModal:false,
            visibleDeleteEntryModal:false,
            children:null
        };
    }
    showMessage(message,image,children){
        this.setState({
            description:message,
            image:image,
            children:children,
            visibleMessageModal:true
        });
    }
    linkToHome(reload){
        if(!reload){
            browserHistory.push('/home');
        }else {
            location.reload();
        }
    }
    _onPressSubmit(){
        var {urlbData} = this.props.entry;
        var urlaData= this.props.entry.entryData.urlaData;
        var {entryDatePicker,voteTimePanel,pickVotePanel} = this.props;
        if(!this.props.user.isLogin && !this.props.gFormInput.entryForm.finalCheck){
            this.showMessage(<FormattedMessage id="entryPickPage.enterNameMessage" defaultMessage="Hãy điền tên vào trước khi bấm tham gia." />);
            return;
        }
        if(!checkIsVoted(pickVotePanel.votes)){
            this.showMessage(<FormattedMessage id="entryPickPage.locationSelectionPrompt" />,'/images/message/nullVote.png');
            return;
        }
        if(urlaData.schedule.mode==1){
            if(!checkIsSelectedDate(voteTimePanel.selectedDays)){
                this.showMessage(<FormattedMessage id="entryPickPage.selectTimeSlotMessage" />,'/images/message/nullDate4.png');
                return;
            }
        }else {
            if(!checkIsSelectedDate(entryDatePicker.selectedDays)){
                if(entryDatePicker.pickMode=='list'){
                    if(entryDatePicker.selectedDays[0].genTime){
                        this.showMessage(<FormattedMessage id="entryPickPage.selectDateTimeAvailabilityMessage" />,'/images/message/nullDate3.png');
                    }else {
                        this.showMessage(<FormattedMessage id="entryPickPage.selectDateAvailabilityMessage" />,'/images/message/nullDate2.png');
                    }
                }else {
                    this.showMessage(<FormattedMessage id="entryPickPage.selectDateAvailabilityMessage" />,'/images/message/nullDate.png');
                }
                return;
            }
        }
        var name = (this.props.user.isLogin)?
                        this.props.user.userData.displayName:
                        this.props.gFormInput.entryForm.data.name;
        var stickerData = this.props.collectionBox.stickerData.selectedData['commentBox'];
        var textComment =  this.props.commentBox.textComment;
        var data = {
            urlbId:urlbData.id,
            hashUrlb:urlaData.hashUrlb,
            selectedDays:(urlaData.schedule.mode)?voteTimePanel.selectedDays:entryDatePicker.selectedDays,
            votes:pickVotePanel.votes,
            name:name,
            stickerData:stickerData,
            textComment:textComment
        };
        if(urlbData.id||urlbData.flag==2){//truong hop da pick va muon chinh sua
            this.props.entryActions.updateSubmitDataToServer(data);
        }else {//truong hop chua pick lan nao
            this.props.entryActions.submitDataToServer(data);
        }
    }
    _onPressBookmark(){
        if(!this.props.user.isLogin){
            var loginButton = <LoginFacebookButton align/>;
            this.showMessage(<FormattedMessage id="entryPickPage.loginRequiredMessage" />,null,loginButton);
            return;
        }else {
            var urlaData= this.props.entry.entryData.urlaData;
            var data = {
                hashUrlb:urlaData.hashUrlb,
            };
            this.props.entryActions.bookmarkDataToServer(data,this.linkToHome);
        }
    }
    cancelEntry(){
        var {urlbData} = this.props.entry;
        var urlaData= this.props.entry.entryData.urlaData;
        this.props.entryActions.cancelEntryToServer(urlbData.id,urlaData.hashUrlb,this.linkToHome);
    }
    notEdit(){
        this.showMessage(<FormattedMessage id="entryPickPage.changeSelectionMessage" />,'/images/message/nullDate4.png');
    }
    _renderSubmitButton(){
        if(this.props.deadLine){
            return(
                <div className={styles.deadLineText}>
                    <i className="fa fa-lock" aria-hidden="true"></i> <FormattedMessage id="entryPickPage.eventExpiredMessage" />
                </div>
            );
        };
        var urlb = this.props.entry.urlbData;
        var isLoading = this.props.entry.pageController.isLoading;
        if(!isLoading){
            if(urlb.id){
                switch (urlb.flag) {
                    case 1:
                        var urlaData= this.props.entry.entryData.urlaData;
                        var {entryDatePicker,voteTimePanel,pickVotePanel} = this.props;
                        var data = {
                            selectedDays:(urlaData.schedule.mode)?voteTimePanel.selectedDays:entryDatePicker.selectedDays,
                            votes:pickVotePanel.votes
                        };
                        //if(this.props.entry.pageController.isEdited){
                        var editButton = <GButton
                            default='gray'
                            style={{width:'100%',height:47,fontSize:10}}
                            onClick={this.notEdit.bind(this)}>
                            <i className="fa fa-pencil-square-o" aria-hidden="true"style={{display:'block',fontSize:24}}></i> <FormattedMessage id="entryPickPage.changeMessage" /></GButton>;
                        if(JSON.stringify(getSubmitData(data))!=JSON.stringify(getPickedData(urlb))){
                            editButton= <GButton
                                    default='blue'
                                    style={{width:'100%',height:47,fontSize:10}}
                                    onClick={this._onPressSubmit.bind(this)}>
                                    <i className="fa fa-pencil-square-o" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="entryPickPage.changeMessage" /></GButton>;
                        }
                        return(
                            <div style={{display:'flex'}}>
                                <GButton
                                    default='red'
                                    style={{marginRight:10,width:'100%',height:47,fontSize:10}}
                                    onClick={()=>this.setState({visibleDeleteEntryModal:true})}>
                                        <i className="fa fa-times" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="entryPickPage.cancelParticipationMessage" />
                                </GButton>
                                <div style={{flex:1}}></div>
                                {editButton}
                            </div>
                        );
                    case 2:
                        return(
                            <div style={{display:'flex'}}>
                                <GButton
                                    default='gray'
                                    style={{marginRight:10,width:'100%',height:47,fontSize:10}}
                                    onClick={this._onPressBookmark.bind(this)}>
                                        <i className="fa fa-star" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="entryPickPage.cancelFollowMessage" />
                                </GButton>
                                <div style={{flex:1}}></div>
                                <GButton
                                    default='green'
                                    style={{width:'100%',height:47,fontSize:10}}
                                    onClick={this._onPressSubmit.bind(this)}>
                                    <i className="fa fa-check" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="entryPickPage.joinMessage" />
                                </GButton>
                            </div>
                        );
                        break;
                    default:
                }

            }else {
                return(
                    <div style={{display:'flex'}}>
                        <GButton
                            default='gray'
                            style={{marginRight:10,width:'100%',height:47,fontSize:10}}
                            onClick={this._onPressBookmark.bind(this)}>
                                <i className="fa fa-star-o" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="entryPickPage.followMessage" />
                        </GButton>
                        <div style={{flex:1}}></div>
                        <GButton
                            default='primary'
                            style={{width:'100%',height:47,fontSize:10}}
                            onClick={this._onPressSubmit.bind(this)}>
                            <i className="fa fa-check" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="entryPickPage.joinMessage" />
                        </GButton>
                    </div>
                );
            }
        }else {
            return(
                <center style={{marginTop:10}}>
                    <i className={"fa fa-refresh fa-spin "} style={{fontSize:'42',color:'#02499d'}} aria-hidden="true"></i>
                </center>
            );
        }
    }
    closeMessageModal(){
        this.setState({
            visibleMessageModal:false
        });
    }
    _renderVoteTime(){
        //1 la vote 0 la chon khoang: hien tai chon khoang bi khoa lai
        var hashUrla = this.props.hashUrla;
        var hashUrlb = this.props.entry.entryData.urlaData.hashUrlb;
        // co hashUrla coi nhu giay thong hanh admin cho keo

        if(this.props.entry.entryData.urlaData.schedule.mode){
            return (<VoteTimePanel hashUrla={hashUrla} hashUrlb={hashUrlb}/>);
        }else {
            var deadLine = this.props.deadLine;
            return(
                <EntryDatePicker deadLine={deadLine}/>
            );
        }
    }
    render() {
        var hashUrlb = this.props.entry.entryData.urlaData.hashUrlb;
        var urlaData = this.props.entry.entryData.urlaData;
        var hashUrla = this.props.hashUrla;
        var deadLine = this.props.deadLine;
        var entryDeadLine = this.props.entry.entryData.urlaData.deadLine;
        var {isShowguide} = this.props.entry.pageController;
        return (
            <div>
                <DialogModal
                    description={this.state.description}
                    children={this.state.children}
                    cancelLabel= {<FormattedMessage id="entryPickPage.understoodMessage" />}
                    image={this.state.image}
                    visible={this.state.visibleMessageModal}
                    closeModal={this.closeMessageModal.bind(this)}/>
                <DialogModal
                    description={<FormattedMessage id="entryPickPage.confirmCancelMessage" />}
                    submitModal={this.cancelEntry.bind(this)}
                    visible={this.state.visibleDeleteEntryModal}
                    closeModal={()=> this.setState({visibleDeleteEntryModal:false})}/>
                <GModal visible={isShowguide} closeModal={()=> this.props.entryActions.setDisplayEntryGuide(false)}>
                    <EntryGuide urlaData={urlaData} closeModal={()=> this.props.entryActions.setDisplayEntryGuide(false)}/>
                </GModal>
                <div className={globalStyles.white_box} style={{paddingBottom:10}}>
                    <p className={styles.label}><FormattedMessage id="entryPickPage.yourNameMessage" /></p>
                    <UserNameWithIntl
                            user={this.props.user}
                            inputActions={this.props.inputActions}
                            inputData={this.props.gFormInput}
                            language={this.props.language}/>

                </div>
                <DeadLineTimer deadLine ={entryDeadLine} isDeadLine={deadLine} hashUrla={hashUrla}/>
                <div className={globalStyles.white_box} style={{paddingBottom:10}}>
                    <PickVotePanel
                        deadLine={deadLine}
                        hashUrlb={hashUrlb}
                        hashUrla={hashUrla}/>
                    <div className={globalStyles.divider}></div>
                    {this._renderVoteTime()}
                </div>
                <div className={styles.submitBox}>
                    {this._renderSubmitButton()}
                </div>
            </div>
        );
    }
}

class UserName extends React.Component {
    _renderUserName() {
        if (!this.props.user.isLogin) {
            const intl = this.props.intl;
            const placeholderText = intl.formatMessage({ id: 'entryPickPage.nicknameMessage' });
            return (
                <div>
                    <GFormInput type="text"
                        name="name"
                        placeholder={placeholderText}
                        form="entryForm"
                        default="large"
                        containerStyle={{marginBottom:0}}
                        checks={['required']}
                        inputActions={this.props.inputActions}
                        inputData={this.props.inputData}
                        lang={this.props.language.lang} />
                </div>
            )
        }
        else {
            var avatarUrl = this.props.user.userData.profile.avatarUrl;
            return (
                <div className={styles.fbUserNameBoard}>
                    <object data={avatarUrl} type="image/jpg" className={styles.fbUserAvatar}>
                        <img src='/images/control/nullAvatar.jpg' className={styles.fbUserAvatar}/>
                    </object>
                    <div className={styles.fbUserName}>{this.props.user.userData.displayName}</div>
                </div>
            )
        }
    }
    render() {
        return(
            this._renderUserName()
        )
    }
}
const UserNameWithIntl = injectIntl(UserName);

function checkIsGenTime(selectedDays){
    for(var i=0;i<selectedDays.length;i++){
        if(selectedDays[i].genTime){
            return true;
        }
    }
    return false;
}
function getSubmitData(data){
    var selectedDays = [];
    for (var i=0;i<data.selectedDays.length;i++){
        if(data.selectedDays[i].picked){
            selectedDays.push({
                id:data.selectedDays[i].id
            });
        }
    }
    var voted = [];
    var votes = data.votes;
    if(votes.length>1){
       for(var i =0;i<votes.length;i++){
           if(votes[i].picked){
               voted.push({
                   id:votes[i].id
               });
           }
       }
    }
    return {
        selectedDays:_.orderBy(selectedDays, ['id'], ['desc', 'asc']),
        voted:_.orderBy(voted, ['id'], ['desc', 'asc'])
    };
}

function getPickedData(data) {
    var selectedDays = [];
    for (var i = 0; i < data.user_time_votes.length; i++) {
        selectedDays.push({
            id:data.user_time_votes[i].timeVoteId
        });
    }
    var voted = [];
    for (var i = 0; i < data.user_votes.length; i++) {
        voted.push({
            id:data.user_votes[i].voteId
        });
    }
    return {
        selectedDays:_.orderBy(selectedDays, ['id'], ['desc', 'asc']),
        voted:_.orderBy(voted, ['id'], ['desc', 'asc'])
    };
}

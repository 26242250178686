import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/loginFacebookButton_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class LoginFacebookButton extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            isLoading:false
        }
    }
    render() {
        var style = (this.props.style)?this.props.style:{};
        if(this.props.align){
            style.justifyContent='center';
        }
        if(this.props.isLoading || this.state.isLoading){
            return(
                <div style={{justifyContent:'center',display:'flex'}}>
                    <i className={"fa fa-refresh fa-spin "} style={{fontSize:'17',color:'#02499d'}} aria-hidden="true"></i>
                </div>
            );
        }else {
            return(
                <a className={styles.facebook_button} href='/auth/facebook' style={style} onClick={()=>this.setState({isLoading:true})}>
                    <i style={{fontSize:'17'}} className="fa fa-facebook" aria-hidden="true"></i>&nbsp;
                    <FormattedMessage id="loginFacebookButton.loginMessage" />
                </a>
            );
        }
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import HeaderBar from 'app/components/headerBar/headerBar.jsx';

import { browserHistory, Link } from 'react-router';
import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/main_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import LanguageSwitch from 'app/components/languageSwitch/languageSwitch.jsx';
import LoginModal from 'app/components/login/loginModal.jsx';
import ControlPanel from 'app/containers/controlPanel/controlPanel_container.jsx';
import Sidebar from 'react-sidebar';

import { IntlProvider } from 'react-intl';
import enMessages from '../../locales/en/translation.json';
import jpMessages from '../../locales/jp/translation.json';
import vnMessages from '../../locales/vi/translation.json';
import thMessages from '../../locales/th/translation.json';

const messages = {
    en: enMessages,
    ja: jpMessages,
    vi: vnMessages,
    th: thMessages
};

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }
    onSetSidebarOpen(open) {
        this.props.controlPanelActions.setDisplayControlPanel(open);
    }
    getLanguagePreference() {
        // 1. Kiểm tra xem có cài đặt ngôn ngữ trong localStorage hay không.
        const storedLanguage = localStorage.getItem('language');

        // 2. Nếu có, trả về ngôn ngữ đã lưu.
        if (storedLanguage) {
            return this.props.langBarActions.setLangData(storedLanguage);
        }

        // 3. Nếu không, lấy ngôn ngữ từ trình duyệt.
        const browserLanguage = this.getBrowserLanguage();
        const realLanguage = messages[browserLanguage]?browserLanguage:'en';
        // 4. Lưu ngôn ngữ từ trình duyệt vào localStorage cho lần sử dụng sau.
        localStorage.setItem('language', realLanguage);
        this.props.langBarActions.setLangData(realLanguage)
        // 5. Trả về ngôn ngữ từ trình duyệt.
        return browserLanguage;
    }
    getBrowserLanguage() {
        const browserLanguages =
            navigator.languages || // Trình duyệt hỗ trợ nhiều ngôn ngữ
            [navigator.language || navigator.userLanguage]; // Trình duyệt chỉ hỗ trợ một ngôn ngữ
        return browserLanguages[0]; // Lấy ngôn ngữ ưu tiên nhất
    }
    componentDidMount() {
        var self = this;
        this.getLanguagePreference();
        browserHistory.listen(function (ev) {
            self.checkDisplayControlPanel(ev.pathname, ev.query, window.innerWidth);
        });
        this.checkDisplayControlPanel(this.props.children.props.location.pathname, this.props.children.props.location.query, window.innerWidth);
    }
    checkDisplayControlPanel(path, query, width) {
        var { controlPanelActions } = this.props;
        if (width > 600) {
            switch (path) {
                case '/': // chon tab dang hot o index
                    controlPanelActions.setDisplayControlPanel(false);
                    controlPanelActions.setDocked(false);
                    controlPanelActions.setMode('docked');
                    break;
                case '/index': // chon tab theo doi o index
                    controlPanelActions.setDisplayControlPanel(false);
                    controlPanelActions.setDocked(false);
                    controlPanelActions.setMode('docked');
                    break;
                case '/home': // chon tab theo doi o index
                    controlPanelActions.setDisplayControlPanel(false);
                    controlPanelActions.setDocked(false);
                    controlPanelActions.setMode('docked');
                    break;
                default: // mac dinh dang hot neu khong gan tab query
                    controlPanelActions.setDisplayControlPanel(true);
                    controlPanelActions.setDocked(true);
                    controlPanelActions.setMode('docked');
                    break;
            }
        } else {
            controlPanelActions.setDisplayControlPanel(false);
            controlPanelActions.setDocked(false);
        }
    }
    _renderLoginModal() {
        var visible = this.props.controlPanel.isShowLoginModal;
        return <LoginModal closeModal={this.props.controlPanelActions.switchIsShowLoginModal} visible={visible} />;
    }
    render() {
        const { lang } = this.props.user;
        var pathname = this.props.location.pathname;
        var headerStyle = (pathname == '/' || pathname == '/index') ? 'white' : 'gray';
        var { controlPanelDocked, controlPanelDisplay } = this.props.controlPanel;
        var sidebarContent = <ControlPanel
            headerStyle={headerStyle}
            path={this.props.children.props.location.pathname}
            query={this.props.children.props.location.query} />;
        return (
            <IntlProvider locale={lang} messages={messages[lang]}>
                <div className={styles.main_container}>
                    {this._renderLoginModal()}
                    <Sidebar
                        sidebar={sidebarContent}
                        open={controlPanelDisplay}
                        touch={false}
                        styles={{
                            content: {
                                overflowY: 'none'
                            },
                            root: {
                                overflow: 'none'
                            },
                            sidebar: {
                                position: 'fixed',
                                backgroundColor: '#fff'
                            }
                        }}
                        docked={controlPanelDocked}
                        onSetOpen={this.onSetSidebarOpen.bind(this)}>
                        <div className={styles.main_body}>
                            <HeaderBar
                                default={headerStyle}
                                userActions={this.props.userActions}
                                lang={this.props.location.query.lang}
                                index={this.props.index}
                                controlPanelActions={this.props.controlPanelActions}
                                controlPanelData={this.props.controlPanel}
                                user={this.props.user} />
                            {this.props.children}
                            <div className={globalStyles.footer_background} style={{ marginTop: '0' }}>
                                <div className={globalStyles.footer_container}>
                                    <Link style={{
                                        textAlign: 'center',
                                        color: '#fff',
                                        fontWeight: 700,
                                        marginBottom: '10px'
                                    }} to="/feedback">
                                        <i className="fa fa-thumbs-up" aria-hidden="true"></i> <FormattedMessage id="main.pickbozFeedbackMessage" />
                                    </Link>
                                    <div className={globalStyles.footer_title}>
                                        <div className={globalStyles.copyright}>© GDV |&nbsp;</div>
                                        <a style={{ color: '#fff' }} href="https://www.ghidanhviet.com/" target="_blank">GDV Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sidebar>
                </div>
            </IntlProvider>
        )
    }
}
//<div style={{flex:1}}></div>
// <div style={{flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
//     <LanguageSwitch defaultLang='vn' lang={this.props.lang} onChange={this.onChangeLanguage.bind(this)}/>
// </div>
